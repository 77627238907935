import React, { useEffect, useMemo } from 'react';

import { Provider, connect, ConnectedProps } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import Bugsnag from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react';
import '@datadog/browser-rum/bundle/datadog-rum';
import Helmet from 'react-helmet';

import { store, persistor } from 'src/state/store';
import { State } from 'src/state/root-state';
import Selectors from 'src/state/root-selectors';
import PvolveSelectors from '@pvolve/sdk/src/app/selectors';
import { MaintenanceSwitcher } from 'src/components/Maintenance';

import REMOTE_CONFIG from '../../.remote_config.json';
import Colors from 'src/utils/colors';

const bugsnag = Bugsnag.start({
    apiKey: process.env.BUGSNAG_API_KEY || REMOTE_CONFIG?.bugsnag?.apiKey,
    appVersion: process.env.GITHUB_RUN_NUMBER || '0',
    enabledReleaseStages: ['development', 'dev', 'qa', 'qa2', 'qa3', 'stg', 'production'],
    plugins: [new BugsnagPluginReact()],
    releaseStage: process.env.STAGE || process.env.NODE_ENV,
});

const ErrorBoundary = Bugsnag.getPlugin('react')!.createErrorBoundary(React);

const connector = connect((state: State) => ({
    stripeKey: Selectors.config.stripeKey(state),
    userId: Selectors.auth.userId(state),
    featureFlags: PvolveSelectors.config.featureFlags(state),
}));

const StripeElements: React.FC<ConnectedProps<typeof connector>> = ({
    stripeKey,
    children,
    userId,
    featureFlags,
}) => {
    /**
     * Piggy backing off of this connected component to set the Bugsnag user id
     */

    const darkMode = window.matchMedia('(prefers-color-scheme: dark)').matches;

    useEffect(() => {
        if (userId) {
            bugsnag.setUser(userId);
        }
    }, [userId]);

    useEffect(() => {
        if (typeof window !== undefined && window.DD_RUM && process.env.STAGE === 'production') {
            window.DD_RUM.init({
                applicationId: process.env.DD_APP_ID,
                clientToken: process.env.DD_CLIENT_TOKEN,
                site: 'datadoghq.com',
                service: `Pvolve Web  ${process.env.STAGE}`,
                env: process.env.STAGE,
                resourceSampleRate: 100,
                sampleRate: 100,
                replaySampleRate: 0,
            });
        }
    }, []);

    const stripePromise = useMemo(() => (stripeKey ? loadStripe(stripeKey) : null), [stripeKey]);

    return (
        <Elements stripe={stripePromise}>
            <Helmet>
                <link rel="icon" type="image/svg+xml" href="/favicon.ico" />
                <link rel="icon" type="image/png" sizes="512x512" href="android-chrome-512x512" />
                <link rel="icon" type="image/png" sizes="192x192" href="android-chrome-192x192" />
                <link rel="icon" type="image/png" sizes="32x32" href="favicon-32x32.png" />
                <link rel="icon" type="image/png" sizes="32x32" href="favicon-16x16.png" />

                {/* Safari */}
                <link rel="apple-touch-icon" sizes="180x180" href="apple-touch-icon.png" />

                <link rel="manifest" href={'/site.webmanifest'} />
                <meta name="msapplication-TileColor" content="#00aba9" />
                <meta name="theme-color" content="#ffffff" />
            </Helmet>
            {children}
        </Elements>
    );
};
const ConnectedElements = connector(StripeElements);

/**
 * Wrap application with Redux Provider
 * PersistGate to prohibit rendering of UI until state has been rehydrated from storage
 */

export default ({ element }: { element: React.ReactNode }) => {
    return (
        <ErrorBoundary>
            <Provider store={store}>
                <PersistGate loading={null} persistor={persistor}>
                    <MaintenanceSwitcher>
                        <ConnectedElements>{element}</ConnectedElements>
                    </MaintenanceSwitcher>
                </PersistGate>
            </Provider>
        </ErrorBoundary>
    );
};
